import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaTagService {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  setTitle(title: string){
    this.title.setTitle(title);
  }

  setMetaTag(tagName: string, value: string){
    const tag = { name: tagName, content: value };
    const attributeSelector = `name="${tagName}"`;
    this.meta.removeTag(attributeSelector)
    this.meta.addTag(tag);
  }
}
